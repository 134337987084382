import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import BackgroundLogo from '../components/background_logo';

const IndexPage = ({data}) => (
  <Layout showPageLogo={false}>
    <SEO title="home" />
    <BackgroundLogo className="indexCoffeeCup" />
    <div className="fullPageText">
      SPRING <br/> CITY <br/> COFFEE
    </div>
  </Layout>
);

export default IndexPage;
